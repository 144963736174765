import Map from '../components/Map.js';
import Page from '../components/Page.js';

const Router = {
    basePath: document.body.dataset.basepath,

    initialize() {
        const url = new URL(Router.basePath);
        let normalizedBasePath = url.pathname.endsWith('/') ? url.pathname : url.pathname + '/';
        let path = window.location.pathname.replace(normalizedBasePath, '');

        window.addEventListener('popstate', Router.routeBack.bind(this));
        history.replaceState({ sliderValue: 0 }, '', window.location.href);
        Router.route();
        Page.initialize(path);
    },

    navigate(state = {}, path) {
        const sliderValue = document.querySelector('.date-slider') ? document.querySelector('.date-slider').value : 0;
        state.sliderValue = sliderValue;
        history.pushState(state, '', Router.basePath + '/' + path);
    },

    route() {
        const url = new URL(this.basePath);
        let normalizedBasePath = url.pathname.endsWith('/') ? url.pathname : url.pathname + '/';
        let path = window.location.pathname.replace(normalizedBasePath, '');

        const state = history.state || {};
        const sliderValue = parseInt(state.sliderValue, 10) || null;

        Router.determineView(path, sliderValue);
    },

    routeBack(event) {
        const url = new URL(Router.basePath);
        let normalizedBasePath = url.pathname.endsWith('/') ? url.pathname : url.pathname + '/';
        let path = window.location.pathname.replace(normalizedBasePath, '');

        const state = event.state || {};
        const sliderValue = parseInt(state.sliderValue, 10) || null;

        Router.determineView(path, sliderValue);
        
    },

    determineView(path, sliderValue) {
        if (!path) {
            Map.initialize(null, sliderValue);
        } 
        else {
            Map.initialize(path, sliderValue);
        }
    },

    updateSliderState(sliderValue) {
        const state = history.state || {};
        state.sliderValue = sliderValue;
        history.replaceState(state, '', window.location.href);
    }
};

export default Router;